import React, { useRef } from "react"
import {HelmetDatoCms} from 'gatsby-source-datocms';
import {graphql} from "gatsby";
import { colors } from "../style/style";
import Layout from '../components/layout';
import PageHeader from "../components/PageHeader/PageHeader";
import SlideQuotingHero from "../components/Slides/SlideQuotingHero";
import SlidePrograms from "../components/Slides/SlidePrograms";
import SlideFooter from "../components/SlideFooter";

export const quotingQuery = graphql`
    {
        seo: datoCmsSeo(slug: { eq: "online-quoting" }) {
            meta: seoMetaTags {
                tags
            }
        }
        datoCmsQuoting {
            logo {
                logo {
                    alt
                    url
                }
                id
            }
            card {
                id
                text
                quoteButtonLink
                requestLoginLink
                carrierCard {
                    name
                    logo {
                        alt
                        url
                    }
                }
            }
        }
    }
`;

const OnlineQuoting = ({ data }) => {
    const headerRef = useRef();
    const {
        datoCmsQuoting: { logo: logos, card },
        seo,
    } = data;
    return (
        <Layout>
            <HelmetDatoCms bodyAttributes={{ class: 'overflow-auto' }} seo={seo?.meta} />
            <PageHeader ref={headerRef} color={colors.dark} />
            <SlidePrograms  card={card} />
            <SlideQuotingHero isClipped={true} logos={logos} />
            <SlideFooter />
        </Layout>
    );
};

export default OnlineQuoting;
