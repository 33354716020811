import React from "react";
import {motion} from "framer-motion";
import styled from "styled-components";
import * as css from './slides.module.css';
import {colors} from "../../style/style";
import useWindowDimensions from "../../utils/hooks/useWindowWidth";
import PageSection from "../PageSection";
import Arrow from '../../components/Arrow'
import arrowDown from "../../static/images/icons/arrow_down.svg";

import SlideInElement from "../../components/SlideInElement/SlideInElement";
import {Outline} from "../../components/PageParts";
import bgDarkElement from "../../static/images/bg-element-dark.svg"
import {fadeInLeft, fadeInUp} from "../../style/motion-variants";
import { useCycle } from "framer-motion";
import FormPopup from "../Form/FormPopup";


const HeroContainer = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 1;

  @media (max-width: 1024px) {
    display: block
  }
`
const Logo = styled.img`
  width: 225px;
  height: 63px;
  object-fit: contain;
  margin-bottom: 21px;

  @media (max-width: 1024px) {
    width: 180px;
    height: 50px;
    margin-bottom: 15px;
  }
`
const CarriersLogo = styled.div`
    display: flex;
    flex-wrap: wrap;
    max-width: 408px;
    margin: 0 -20px;

    @media (max-width: 1024px) {
        max-width: none;
        margin: 95px -30px 95px;
    }

    @media (max-width: 568px) {
        max-width: none;
        margin: 40px -24px 75px;
    }

    img {
        width: 96px;
        height: 50px;
        object-fit: contain;
        margin: 25px 20px;
        filter: grayscale(1);
        transition: filter 0.3s ease;

        @media (max-width: 1024px) {
            margin: 12px 30px;
        }

        @media (max-width: 568px) {
            max-width: 72px;
            max-height: 30px;
            margin: 12px 24px;
        }

        &:hover {
            filter: grayscale(0);
        }
    }
`;
const StyledButton = styled.button`
    display: inline-block;
    min-width: 168px;
    padding: 11px;
    border: 2px solid ${colors.dark};
    border-radius: 50px;
    color: ${colors.dark};
    font-size: 14px;
    line-height: 19px;
    font-weight: 700;
    background: transparent;
    transition: border 0.3s ease;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
    &:hover {
        border: 2px solid ${colors.orange};
        text-decoration: none;
    }

    &:active,
    &:focus {
        outline: none;
        text-decoration: none;
    }

    @media (max-width: 568px) {
        min-width: 153px;
    }
`;
const Button = styled.button`
    display: inline-block;
    background: none;
    border: none;
    cursor: pointer;
    color: ${colors.dark};
    font-size: 14px;
    font-weight: 700;
    text-decoration: none;
    text-align: center;
    margin-left: 35px;

    &:hover {
        text-decoration: none;
        color: ${colors.dark};
    }

    &:active,
    &:focus {
        outline: none;
        text-decoration: none;
    }

    &[data-screen='desktop'] {
        @media all and (max-width: 767px) {
            display: none;
        }
    }

    &[data-screen='mobile'] {
        @media all and (min-width: 768px) {
            display: none;
        }
    }
`;
const Title = styled.h2`
    display: flex;
    flex-direction: column;
    margin: 0 0 52px;
    font-size: 72px;
    line-height: 90px;
    font-family: 'Poppins', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: bold;
    color: ${colors.dark};
    white-space: nowrap;

    @media (max-width: 1024px) {
        margin: 0 0 40px;
    }

    @media (max-width: 560px) {
        font-size: 56px;
        line-height: 60px;
    }

    @media (max-height: 900px) {
        font-size: 66px;
        line-height: 72px;
    }

    @media (max-width: 470px) {
        font-size: 34px;
        line-height: 40px;
    }
`;
const HeroBlock = styled(motion.div)`
    max-width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;

    @media all and (max-width: 1024px) {
        display: block;
        height: auto;
        max-width: none;
    }
`;
const Products = styled.div`
    margin-top: 66px;

    @media all and (max-width: 1024px) {
        padding-bottom: 60px;
    }

    @media (max-height: 900px) {
        margin-top: 40px;
    }

  h3 {
    font-weight: bold;
    font-size: 18px;
    line-height: 145%;
    margin-bottom: 24px;
    text-align: right;
  }
`;
const ProductsGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin: -5px;

  span {
    display: block;
    font-weight: 500;
    font-size: 14px;
    line-height: 145%;
    padding: 4px 9px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    margin: 5px;
  }

  @media all and (max-width: 1024px) {
    max-width: none;
  }
`;
const SlideInner = styled.div`
  display: flex;
  flex-direction: column; 
  justify-content: center;
  height: 100%;

  @media all and (max-width: 1024px) {
    display: block;
  }
`;

function SlideQuotingHero({ logos, handleClick }) {
    const { width } = useWindowDimensions();
    const [isOpen, toggleForm] = useCycle(false, true); 
    const SlideInnerWrapper = () => {
        return (
            <SlideInner>
                <HeroContainer>
                    <motion.div
                        exit="exit"
                        variants={fadeInUp}
                        initial="initial"
                        animate="animate"
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Title>
                            <span>Single Entry</span>
                            <Outline>Multi-Carrier</Outline>
                            <span>Submission</span>
                            <span>Platform</span>
                        </Title>

                        <div>
                            <StyledButton  onClick={toggleForm}>
                                Request Login
                            </StyledButton>
                            <FormPopup 
                            isOpen={ isOpen }
                            type={'iConnect'}
                            onClose={ toggleForm } 
                            title={ "Get access"} /> 
                        </div>
                    </motion.div>

                    <HeroBlock exit="exit" variants={fadeInUp} initial="initial" animate="animate">
                        <CarriersLogo>
                            {logos.map(({ id, logo }) => (
                                <img key={id} src={logo.url} alt={logo.alt} />
                            ))}
                        </CarriersLogo>

                        <Products>
                            <h3>Available Products:</h3>

                            <ProductsGrid>
                                <span>BOP</span>
                                <span>Workers Compensation</span>
                                <span>General Liability</span>
                                <span>Cyber Liability</span>
                                <span>Commercial Auto</span>
                                <span>Stand Alone Personal Umbrella</span>
                            </ProductsGrid>
                        </Products>
                    </HeroBlock>
                </HeroContainer>
            </SlideInner>
        );
    };

    return (
        <PageSection
            background={colors.light}
            padding={'8vh 0'}
            maxHeight={width < 1024 ? '100%' : '100vh'}
            height = {'100%'}
        >
            <SlideInElement top={20} left={-290} bg={bgDarkElement} variant={fadeInLeft} />
            <div className={`${css.wrapper} ${css.onlineQuotingWrapper}`} style={{ padding: '0 30px' }}>
                {SlideInnerWrapper()}
            </div>
            {typeof handleClick === 'function' && width > 767 ? <Arrow icon={arrowDown} onClick={handleClick} /> : null}
        </PageSection>
    );
}

export default SlideQuotingHero;
