import React, { useState, useEffect, useRef  } from "react";
import { motion, useCycle, AnimatePresence } from "framer-motion";
import styled from "styled-components";
import * as css from './slides.module.css';
import { colors } from "../../style/style";

import { SubTitle, TagLine } from "../../components/PageParts";
import FormPopup from "../../components/Form/FormPopup";
import { fadeInLeft, fadeInUp, fadeInRight } from "../../style/motion-variants";
import SwiperCore, { A11y, Mousewheel, Navigation, Pagination, Scrollbar } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import SlideInElement from "../../components/SlideInElement/SlideInElement";
import bgDarkElement from "../../static/images/bg-element-dark.svg"
import useWindowDimensions from "../../utils/hooks/useWindowWidth";

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Mousewheel])


const CardLogo = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  ${props => props.isMobile ? "" : "@media (min-width: 768px){ &:hover{.card-inner{display: block;}}}"}
  &.active{
    .card-inner {
      display: block;
    }
  }
  input {
    display: none;
    &:checked {
      & ~ .card-inner .box:after {
        display: block;
        background: #FF5C00;
      }
      & ~ .card-inner label {
        color: #FF5C00;
      }
      & ~ .card-image:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(42, 32, 52, 0.1);
      }
    }
  }

  .card-image {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 0 15px;
    img {
      width: 190px;
      height: 50px;
      object-fit: contain;
      max-width: 100%;
    }
  }

  .card-inner {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    padding: 24px;
    background-color: #FFFFFF;
    box-shadow: 0px 1.8518518209457397px 3.1481480598449707px 0px #00000004, 
                0px 8.148148536682129px 6.518518447875977px 0px #00000007, 
                0px 20px 13px 0px #00000009, 0px 38.51852035522461px 25.481481552124023px 0px #0000000B, 
                0px 64.81481170654297px 46.85185241699219px 0px #0000000E, 0px 100px 80px 0px #00000012;
    cursor: pointer;

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 19px;
      flex-wrap: wrap;
      h3 {
        font-weight: bold;
        font-size: 16px;
        line-height: 145%;
      }

      div {
        display: flex;
        align-items: center;
        cursor: pointer;

        h4 {
          font-weight: 500;
          font-size: 14px;
          line-height: 145%;
          margin-right: 10px;
        }

        
      }
    }

    &-body {
      display: flex;
      flex-wrap: wrap;
      margin: -2px;
      margin-bottom: 24px;
      p {
        font-weight: 500;
        font-size: 14px;
        line-height: 145%;
        opacity: 0.7;
        color: #2A2034;
        padding: 5px 10px;
        border: 1px solid rgba(0, 0, 0, 0.2);
        margin: 2px;
      }
    }
    .button-link{
      display: inline-block;
      min-width: 168px;
      padding: 13px;
      border: 2px solid #2B2135;
      border-radius: 50px;
      color: #2B2135;
      font-size: 14px;
      line-height: 19px;
      font-weight: 700;
      background: transparent;
      -webkit-transition: border .3s ease;
      transition: border .3s ease;
      -webkit-text-decoration: none;
      text-decoration: none;
      text-align: center;
      cursor: pointer;
      widht: 100%;
      &:hover{
        border-color: #FF5C00;
      }
    }
    .box {
      position: relative;
      &:after {
        display: none;
        content: '';
        width: 17px;
        height: 13px;
        background-color: #2B2135;
        -webkit-mask-image: url("data:image/svg+xml,%3Csvg width='17' height='13' viewBox='0 0 17 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.5 6.5L6 11L15.5 1.5' stroke='%23FF5C00' stroke-width='2' stroke-linecap='square'/%3E%3C/svg%3E");
        mask-image: url("data:image/svg+xml,%3Csvg width='17' height='13' viewBox='0 0 17 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.5 6.5L6 11L15.5 1.5' stroke='%23FF5C00' stroke-width='2' stroke-linecap='square'/%3E%3C/svg%3E");
        transition: all .3s;
        margin-right: 9px;
      }
    }
    &-button{
      display: flex;
      flex-direction: column;
      justify-content: center;
        
      .select-programm{
        border: none;
        margin-top: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.3s;
      }
    }
    
  }
  @media all and (max-width: 767.98px) {
    .card-inner {
      position: fixed;
      height: 100vh;
      width: 100%;
      top: 0;
      z-index: 999;
      &-header{
        flex-direction: column;
        align-items: center;
        margin-bottom: 30px;
      }
      &-body{
        justify-content: center;
      }
      .close{
        margin: 0 0 0 auto;
      }
      .card-image{
        padding: 15px 40px;
        text-align: center;
      }
      &-button{
        display: flex;
        align-items: center;
        margin-top: 60px;
        flex-direction: column;
        label{
          font-weight: 700;
          font-size: 14px;
          line-height: 130%;
          color: #2A2034;
          padding: 13px 32px;
          border: none;
          border-radius: 100px;
          background: transparent;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          width: 244px;
          margin-top: 4px;
          cursor: pointer;
        }
        .button-link{
          width: 244px;
        }
      }
      
    }
  }
`
const BoxContainer = styled.div`
  max-width: 100%;
  height: ${props => props.isMobile ? "382px" : "auto"};
  max-width: 1260px;
  margin: 44px auto 0;
  padding: 0px 30px;
  position: relative;
  z-index: 1;
  .swiper-slide:hover {
    z-index: 1;
  }
`
const SwiperContainer = styled(Swiper)`
  max-width: 100%;
  height: ${props => props.isMobile ? "382px" : "auto"};
  max-width: 1260px;
  margin: 44px auto 0;
  padding: 0px 30px;
  position: relative;
  z-index: 1;
  .swiper-slide:hover {
    z-index: 1;
  }

  .swiper-scrollbar {
    height: 3px;
    background-color: transparent;
    top: auto;
    bottom: 38px;
    z-index: 1;
    left: calc((100vw - 1200px) / 2 + 100px);
    width: 1095px;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      height: 1px;
      transform: translate(0, -50%);
      background-color: #C1C1C1;
    }

    .swiper-scrollbar-drag {
      height: 3px;
      background-color: #2A2A2A;
      z-index: 2;
    }
  }

  .button-prev {
    margin-right: 20px;
    background: url("data:image/svg+xml,%3Csvg width='13' height='25' viewBox='0 0 13 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 24L2 12.75L12 1.5' stroke='black' stroke-width='2'/%3E%3C/svg%3E%0A") center center no-repeat;
  }

  .button-next {
    background: url("data:image/svg+xml,%3Csvg width='13' height='25' viewBox='0 0 13 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L11 12.25L1 23.5' stroke='black' stroke-width='2'/%3E%3C/svg%3E%0A") center center no-repeat;
  }
  .swiper-pagination-bullets{
    bottom: 110px;
  }
  .swiper-pagination-bullet-active{
    background: #2A2034;
  }
  @media all and (max-width: 1260px) {
    padding: 50px 30px 82px;
    .swiper-scrollbar {
      left: 130px;
      z-index: 1;
      width: calc(100vw - 160px);
    }
  }

  @media all and (max-width: 767.98px) {
    padding: 10px 10px 100px;
    width: 100%;
    overflow: hidden;
  }
`

const CardCompanyWrapper = styled.div` 
  display: flex;
  flex-wrap: wrap;
  margin: -12px;
  @media all and (max-width: 767.98px){
    margin: 0;
    display: grid;
    grid-auto-columns: minmax(calc(50% - 10px),1fr);
    grid-template-rows: repeat(3, 88px);
    grid-auto-flow: column;
    grid-gap: 15px;
    overflow-x: scroll;
    overflow-y: hidden;
    &::-webkit-scrollbar {
      display: none
    }
  }
`
const SlideInner = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.light};
  
  @media all and (max-height: 720px) {
    justify-content: flex-start;
  }
`

const SliderButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: max-content;
  margin-top: 20px;
  position: relative;
  z-index: 14;
  .button-prev,
  .button-next {
    border: none;
    outline: none;
    width: 27px;
    height: 44px;
    cursor: pointer;
    transition: opacity .3s;

    &:focus {
      outline: none;
      border: none;
    }

    &.swiper-button-disabled {
      opacity: .3;
    }
  }

  @media (max-width:767.98px) {
    margin-top: 0px;
    max-width: 100%;
    justify-content: space-between;
  }
`

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  max-height: 100%;

  @media all and (max-width: 768px) {
    justify-content: center;
    max-height: 100%;
  }

  @media all and (max-width: 415px) {
    justify-content: flex-start;
  }
`
const CardCompany = styled.div`
  padding: 12px;
  @media all and (max-width: 767.98px){
    padding: 7.5px;
    height: 88px;
  }
`

const CtaBlock = styled.div`
  width: 100%;
  max-width: 1260px;
  margin: 40px auto 0;
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  bottom: 0px;
  right: 0;
  left: 0;
  & > div{
    // padding: 8px 12px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    // background: #FFFFFF;
    // box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07), 0px 64.8148px 46.8519px rgba(0, 0, 0, 0.0531481), 0px 38.5185px 25.4815px rgba(0, 0, 0, 0.0425185), 0px 20px 13px rgba(0, 0, 0, 0.035), 0px 8.14815px 6.51852px rgba(0, 0, 0, 0.0274815), 0px 1.85185px 3.14815px rgba(0, 0, 0, 0.0168519);
    // border-radius: 100px;
  }
  p {
    margin: 0 35px 0 0;

    span {
      display: inline-block;
      width: 20px;
    }
  }

  @media all and (max-width: 767.98px) {
    background: #FFFFFF;
    bottom: 0;
    padding: 8px 12px;
    & > div{
      background: transparent;
      box-shadow: none;
      width: 100%;
      justify-content: space-between;
    }
    p {
      margin: 0;
    }
  }
  @media all and (max-width: 480px) {
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    
    p {
      margin: 0;
    }
  }
`

const ShowAll = styled.button`
  font-weight: 700;
  font-size: 14px;
  line-height: 130%;
  color: #2A2034;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  text-decoration: underline;
  &:hover{
    text-decoration: underline;
  }
  @media all and (max-width: 767.98px) {
    display: none;
  }
`

const StyledButton = styled.div`
  display: inline-block;
  min-width: 168px;
  padding: 11px;
  border: 2px solid ${colors.dark};
  border-radius: 50px;
  color: ${colors.dark};
  font-size: 14px;
  line-height: 19px;
  font-weight: 700;
  background: transparent;
  transition: border .3s ease;
  text-decoration: none;
  text-align: center;
  cursor: pointer;

  &:hover {
    border: 2px solid ${colors.orange};
    text-decoration: none;
  }

  &:active, &:focus {
    outline: none;
    text-decoration: none;
  }

  @media (max-width: 568px) {
    min-width: 153px;
  }
`

function SlidePrograms({ card }) {
    const [ isOpen, toggleForm ] = useCycle(false, true);
    const [swiper, setSwiper] = useState(null);
    const [count, setCount] = useState(false);
    const [button, setButton] = useState(false);
    const [width, setWidth ] = useState(1201);
    const [products, setProducts] = useState([]);
    const elemsRef = useRef(null);
    useEffect(()=>{
      onButtonClick(elemsRef);
    },[button]);

    function onButtonClick (elemsRef) {
      const buttonGroup= elemsRef.current.querySelectorAll('.card-inner-button');
      buttonGroup.forEach(element => {
        var label = element.querySelector('label')
        label.addEventListener('click', () => {
          var titleName = label.querySelector('h4');
            label.classList.toggle('active') 
          if(label.classList.contains('active')){
            titleName.textContent = 'Unselect program';
          } else{
            titleName.textContent = 'Select program';
          }    
        })
      });
    };
    useEffect(() => {
      setWidth(window.innerWidth);

      function changeWindowSize() {
          setWidth(window.innerWidth);
      }
      
      window.addEventListener("resize", changeWindowSize);

      return () => {
          window.removeEventListener("resize", changeWindowSize);
      };
      
    }, []);
    useEffect(()=> {
      if(!count){
        elemsRef.current.scrollIntoView({ behavior: 'smooth' })
      };      
    }, [count]);
    const step = width >= 767.98 ? 1 : 1;
    const isMobile = width <= 767.98;

    const setList = (product) => {
        let index = products.indexOf(product);
        if (index > -1) {
            setProducts(products.filter(item => item !== product))
        } else {
            setProducts([...products, product])
        }
    };

    const chunkArray = function (card, chunkSize) {
      var R = [];
      for (var i = 0; i < card.length; i += chunkSize)
        R.push(card.slice(i, i + chunkSize));
      return R;
    };
    var resultCards = chunkArray(card, 6);

    const body = button ? document.body.classList.add('overflow-auto') : '';
    const sliceCards = isMobile ? '' : (count === true) ? card : card.slice(0, 12);
    const renderMobile = () =>{
      
      return(
        <div className="box">
          <SwiperContainer
            onSwiper={(s) => setSwiper(s)}                  
            slidesPerView={1}
            spaceBetween={20}
            pagination={{ clickable: true }}
          >
            {
              resultCards.map((cards, index) => {
                return(
                  <SwiperSlide key={index + 1} className={`${css.silderItems}`}>{
                    cards.map(({ id, carrierCard }) => (
                      <CardCompany key={id} style={{height: "103px", width: "50%"}}>
                          <SlideInner >
                              <CardLogo className={button === id ? 'active': ''}  >
                                <input
                                      checked={products.indexOf(carrierCard.name) !== -1}
                                      onChange={() => {
                                        setList(carrierCard.name);
                                        }
                                      }
                                      id={id}
                                      type="checkbox"
                                  />
                                  <div className="card-image" aria-hidden="true" onClick={() => {setButton(id)}} onKeyDown={() => {setButton(id)}} >
                                      {carrierCard.logo !== null && <img src={carrierCard.logo.url} alt={carrierCard.logo.alt} />}
                                  </div>   
                              </CardLogo>
                          </SlideInner>
                      </CardCompany>
                    ))
                  }
                  </SwiperSlide>
                )
              })
            }
            <SliderButtons>
                <button
                    aria-label="Prev slide"
                    className="button-prev"
                    onClick={() => swiper.slideTo(swiper.realIndex - step)}
                />
                <button
                    aria-label="Next slide"
                    className="button-next"
                    onClick={() => swiper.slideTo(swiper.realIndex + step)}
                />
            </SliderButtons>

          </SwiperContainer>
          <div className="box">
            {card.map(({ id, carrierCard, text, quoteButtonLink }) => (
              
              <CardCompany key={id} style={{height: "103px", width: "50%", display: `${button === id  ? "block" : "none"}`}}>
                  <SlideInner >
                      <CardLogo className={button === id ? 'active': ''}  >
                        <input
                              checked={products.indexOf(carrierCard.name) !== -1}
                              onChange={() => {
                                setList(carrierCard.name);
                                }
                              }
                              id={id}
                              type="checkbox"
                          />
                          <div className="card-image">
                            {carrierCard.logo !== null && <img src={carrierCard.logo.url} alt={carrierCard.logo.alt} />}
                          </div>

                          <div htmlFor={id} className="card-inner">
                              <div className="card-inner-header">
                                  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" className='close' xmlns="http://www.w3.org/2000/svg" onClick={() => setButton(false)}>
                                  <path fillRule="evenodd" clipRule="evenodd" d="M9.58508 10.9993L4.00005 16.5843L5.41426 17.9985L10.9993 12.4135L16.5844 17.9986L17.9986 16.5843L12.4135 10.9993L17.9986 5.41418L16.5844 3.99997L10.9993 9.58505L5.41426 4.00002L4.00005 5.41423L9.58508 10.9993Z" fill="#2A2034"/>
                                  </svg>
                              <div className="card-image" style={{display: 'block'}}>
                                {carrierCard.logo !== null && <img src={carrierCard.logo.url} alt={carrierCard.logo.alt} />}
                                </div>
                                
                              </div>

                              <div className="card-inner-body">
                                  {text.split('\n').map((tag) => (
                                      <p key={tag}>{tag}</p>
                                  ))}
                              </div>
                              <div className="card-inner-button">
                                { quoteButtonLink ? <a href={quoteButtonLink} className="button-link">Log in</a> : '' }
                                <div aria-hidden="true" >
                                  <label htmlFor={id} >
                                      <div className="box" />
                                      <h4>Select program</h4>     
                                  </label>
                                </div>
                              </div>
                          </div>
                      </CardLogo>
                  </SlideInner>
              </CardCompany>
            ))}
          </div>
        </div>
      )
    }
    const renderDesktop = () =>{
      return (
        <BoxContainer>
          <CardCompanyWrapper >
            {sliceCards.map(({ id, carrierCard, text, quoteButtonLink}) => (
                <CardCompany key={id} style={{height: `${isMobile ? "auto" : "136px"}`, width: `${isMobile ? "100%" : "25%"}`, }}>
                    <SlideInner >
                        <CardLogo>
                            <input
                                checked={products.indexOf(carrierCard.name) !== -1}
                                onChange={() => setList(carrierCard.name)}
                                id={id}
                                type="checkbox"
                            />
                            <div className="card-image">
                              {carrierCard.logo !== null && <img src={carrierCard.logo.url} alt={carrierCard.logo.alt} />}
                            </div>

                            <div className="card-inner">
                                <div className="card-inner-header">
                                  <div className="card-image" style={{display: `${isMobile ? 'block': 'none'}`}}>
                                  {carrierCard.logo !== null && <img src={carrierCard.logo.url} alt={carrierCard.logo.alt} />}
                                  </div>
                                  <h3>Products:</h3>
                                </div>

                                <div className="card-inner-body">
                                    {text.split('\n').map((tag) => (
                                        <p key={tag}>{tag}</p>
                                    ))}
                                </div>
                                <div className="card-inner-button">
                                  { quoteButtonLink ? <a href={quoteButtonLink} className="button-link">Log in </a> : '' }
                                    <label htmlFor={id} className="button-link select-programm" >
                                      <div className="box" />
                                      <h4>Select program</h4>     
                                    </label>
                                </div>
                            </div>
                        </CardLogo>
                    </SlideInner>
                </CardCompany>
            ))}
          </CardCompanyWrapper>
        </BoxContainer>
      )
    }
    const renderWrapper = () => {
      return (
          <Wrapper>
              <AnimatePresence>
              <div className="box">
                <motion.div
                    exit="exit"
                    variants={fadeInUp}
                    initial="initial"
                    animate="animate"
                    style={{ width: '100%', maxWidth: '1260px', margin: '0 auto', padding: '0 30px 0' }}
                >
                    <SubTitle color={colors.dark}>
                        <div>Direct</div>
                        <div>Access Programs</div>
                    </SubTitle>

                    <TagLine style={{ marginTop: '20px' }} color={colors.dark}>
                      Sign up below for access to quote and issue policies
                        <br />
                      directly with our partner carriers.
                    </TagLine>
                </motion.div>
                { isMobile ?  renderMobile() : renderDesktop() }
                <CtaBlock>
                  <ShowAll onClick={() => setCount(!count)}>
                    {count ? 'Collapse' : 'Show all'} programs
                  </ShowAll>
                  <div>
                    <p>
                      Selected programs : <span>{products?.length || 0}</span>
                    </p>
                    <StyledButton className={products?.length || 'is-disabled'} onClick={toggleForm}>
                      Request Access
                    </StyledButton>
                  </div>
                </CtaBlock>
              </div>
              </AnimatePresence>
          </Wrapper>
      );
    }

    return <section className={`${css.quoting} ${css.clippedGray} ${css.sectionPrograms}`} ref={elemsRef}>
        <SlideInElement
          bottom={ 90 }
          left={ -290 }
          bg={ bgDarkElement }
          variant={ fadeInLeft }
        />

        <SlideInElement
          top={ 310 }
          right={ width > 768 ? 10 : "-350" }
          bg={ bgDarkElement }
          variant={ fadeInRight }
        />

        {<div>{renderWrapper()}</div>}

        <FormPopup cards={card.map((c) => c?.carrierCard?.name)} products={products} setList={setList} isOpen={ isOpen } onClose={ toggleForm } title={ "Request login" } action={'https://marketing.amsuisse.com/l/868671/2021-06-10/lhpm5l'}/>
    </section>
}

export default SlidePrograms
